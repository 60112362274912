import React from "react"
import * as firebase from "firebase/app"
import "firebase/firestore"

import "./mailing-list.css"

class MailingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     email: "",
     mailingListButtonText: "Subscribe"
    };
    this.updateInput = this.updateInput.bind(this);
    this.addEmail = this.addEmail.bind(this);
  }

  updateInput = (e) => {
    this.setState({
      email: e.target.value,
      mailingListButtonText: "Subscribe"
    });
  }

  addEmail = (e) => {
    e.preventDefault();
    const db = firebase.firestore();
    const storedEmail = this.state.email;
    this.setState({
      email: "",
      mailingListButtonText: "Subscribed!"
    });
    db.collection('mailing_list').add({
      email: storedEmail,
      ts: firebase.firestore.Timestamp.fromDate(new Date())
    });
  };

  render() {
    let firebaseConfig = {
      apiKey: "AIzaSyDur_PpHQyTZO1MMU2T--TdEWbHtneLUPc",
      authDomain: "credit101.firebaseapp.com",
      databaseURL: "https://credit101.firebaseio.com",
      projectId: "credit101",
      storageBucket: "credit101.appspot.com",
      messagingSenderId: "70586303016",
      appId: "1:70586303016:web:f1c062185ad53e46"
    };
    try {
      firebase.initializeApp(firebaseConfig);
    }
    catch (err) {
    }
    return (
        <div className="mailing-list">
          <div className="ml-card">
            <div className="ml-card-mag-strip"></div>
            <div className="ml-card-content">
              <div className="ml-invite">
                <label className="block text-lg font-bold md:text-2xl">Weekly Credit News & Tips</label>
              </div>
              <div className="ml-email py-3 md:py-5">
                <input className="ml-email-input text-sm py-1 md:text-base" onChange={e => this.updateInput(e)} value={this.state.email} type="text" placeholder="alex@example.com"/>
                <div className="subscribe-btn inline-block text-sm py-1 md:text-base">
                  <button className="font-bold text-xs md:text-sm" onClick={this.addEmail}>{this.state.mailingListButtonText}</button>
                </div>
                </div>
              <div className="ml-description text-xs md:text-sm">
                <p>By clicking "Subscribe", you agree to receive emails from Credit 101. We will never share your email. See privacy policy.</p>
              </div>
            </div>
          </div>
        </div>
      )
  }
}

export default MailingList
