import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from 'gatsby'

import MainCover from "../components/home/main-cover"
import AboutCredit101 from "../components/home/about-credit-101"

import SecondCover from "../components/home/second-cover"
import Why101 from "../components/home/why-101"
import MailingList from "../components/home/mailing-list"

const IndexPage = () => {
  const { data } = useStaticQuery(mainCoverQuery);
  const { edges: posts } = data;

  function parseCover(posts) {
    let returnObj = {}
    posts.map(({ node }) => {
      const { title, type, coverImgUrl } = node.frontmatter;
      const { blogPath } = node.fields;
      returnObj = {
        aTitle: title,
        aType: type,
        coverImgUrl: coverImgUrl,
        aPath: blogPath
      }
      return returnObj
    })
    return returnObj
  }
  let mainCoverData = parseCover(posts);

  return (
    <Layout isHome={true}>
      <SEO title="Credit 101 - The Ultimate Credit Guide" keywords={[`credit`, `card`, `score`, `build`]} />
      <MainCover articleType={mainCoverData.aType} articleTitle={mainCoverData.aTitle} coverImgUrl={mainCoverData.coverImgUrl} articlePath={mainCoverData.aPath}></MainCover>
      <AboutCredit101 />
      <SecondCover></SecondCover>
      <Why101></Why101>
      <MailingList></MailingList>
    </Layout>
  )
}
export default IndexPage

const mainCoverQuery = graphql`
query mainCoverQuery {
  data: allMdx(limit: 1, sort: {fields: [frontmatter___date], order: DESC}) {
    edges {
      node {
        id
        fields {
          blogType
          blogPath
        }
        frontmatter {
          title
          author
          date
          type
          coverImgUrl
        }
      }
    }
  }
}`
