import React from "react"
import './main-cover.css'

const MainCover = ({ articleType, articleTitle, isLeft, coverImgUrl, articlePath }) => {

  let coverStyle = {backgroundImage: 'url(' + coverImgUrl + ')'};
  return (
    <div className="maincover" style={coverStyle}>
      <div className="maincover-shadow"></div>
      <div className="maincover-text absolute px-4 pb-4 bottom-0 md:px-8 md:pb-8 lg:pb-12 lg:px-12">
        <div className="maincover-type px-4 py-1"><span className="text-xl font-bold md:text-2xl lg:text-3xl">Feature</span></div><br/>
    	  <a href={articlePath} className="maincover-title px-4 py-1"><span className="text-4xl md:text-5xl lg:text-6xl">{articleTitle}</span></a>
      </div>
    </div>
  )
}

export default MainCover
