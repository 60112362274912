import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

import FeatureCover from './feature-cover'

import "./second-cover.css"

const SecondCover = () => {
  const { score, cards, build } = useStaticQuery(secondCoverQuery);
  const { edges: scorePosts } = score;
  const { edges: cardsPosts } = cards;
  const { edges: buildPosts } = build;

  function parseCover(posts) {
    let returnObj = {}
    posts.map(({ node }) => {
      const { title, type, coverImgUrl } = node.frontmatter;
      const { blogPath } = node.fields;
      returnObj = {
        aTitle: title,
        aType: type,
        coverImgUrl: coverImgUrl,
        aPath: blogPath
      }
      return returnObj
    })
    return returnObj
  }
  let scoreData = parseCover(scorePosts);
  let cardsData = parseCover(cardsPosts);
  let buildData = parseCover(buildPosts);

  return (
    <div className="second-cover flex flex-wrap">
      <div className="w-full md:w-1/2">
        <FeatureCover articleType={buildData.aType} articleTitle={buildData.aTitle} coverImgUrl={buildData.coverImgUrl} articlePath={buildData.aPath} isLeft={true}></FeatureCover>
      </div>
      <div className="w-full md:w-1/2">
        <div className="w-full h-1/2">
          <FeatureCover articleType={scoreData.aType} articleTitle={scoreData.aTitle} coverImgUrl={scoreData.coverImgUrl} articlePath={scoreData.aPath} isLeft={false}></FeatureCover>
        </div>
        <div className="w-full h-1/2">
          <FeatureCover articleType={cardsData.aType} articleTitle={cardsData.aTitle} coverImgUrl={cardsData.coverImgUrl} articlePath={cardsData.aPath} isLeft={false}></FeatureCover>
        </div>
      </div>
    </div>
  )
}

export default SecondCover

const secondCoverQuery = graphql`
  query secondCoverQuery {
    build: allMdx(limit: 1, sort: {fields: [frontmatter___date], order: DESC}, filter: {fields: {blogType: {eq: "build"}}}) {
      edges {
        node {
          id
          fields {
            blogType
            blogPath
          }
          frontmatter {
            title
            author
            date
            type
            coverImgUrl
          }
        }
      }
    }
    cards: allMdx(limit: 1, sort: {fields: [frontmatter___date], order: DESC}, filter: {fields: {blogType: {eq: "cards"}}}) {
      edges {
        node {
          id
          fields {
            blogType
            blogPath
          }
          frontmatter {
            title
            author
            date
            type
            coverImgUrl
          }
        }
      }
    }
    score: allMdx(limit: 1, sort: {fields: [frontmatter___date], order: DESC}, filter: {fields: {blogType: {eq: "score"}}}) {
      edges {
        node {
          id
          fields {
            blogType
            blogPath
          }
          frontmatter {
            title
            author
            date
            type
            coverImgUrl
          }
        }
      }
    }
  }
`
