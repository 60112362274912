import React from "react"

import "./why-101.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPaper, faGlasses } from "@fortawesome/free-solid-svg-icons";

const Why101 = () => {
  return (
    <div className="why101 py-8">
      <div className="text-center text-2xl font-bold md:text-3xl"><span>Why Credit 101</span></div>
      <div className="flex flex-wrap overflow-hidden">

        <div className="w-full overflow-hidden text-center p-4 md:w-1/3 md:p-10">
          <h1 className="text-5xl font-bold">$0</h1>
          <p className="font-semibold md:text-lg lg:text-xl lg:pt-2">Always Free</p>
        </div>

        <div className="w-full overflow-hidden text-center p-4 md:w-1/3 md:p-10">
          <FontAwesomeIcon icon={faHandPaper} className="mb-4" size="4x"/>
          <p className="font-semibold md:text-lg lg:text-xl lg:pt-2">Credit Score & SSN Not Required</p>
        </div>

        <div className="w-full overflow-hidden text-center p-4 md:w-1/3 md:p-10">
          <FontAwesomeIcon icon={faGlasses} className="mb-3" size="4x"/>
          <p className="font-semibold md:text-lg lg:text-xl lg:pt-2">Our Opinions, Not Banks'</p>
        </div>

      </div>
    </div>
  )
}

export default Why101
