import '../tailwind.css'
import PropTypes from 'prop-types'
import React from "react"

import "./feature-cover.css"
const FeatureCover = ({ articleType, articleTitle, isLeft, coverImgUrl, articlePath }) => {
  let featureCoverClasses = "relative featurecover";
  if (isLeft) {
    featureCoverClasses = "relative featurecover left";
  }

  let displayArticleType = "Feature";
  let articleTypeClasses = "featurecover-type px-4 py-1";
  let articleTitleClasses = "featurecover-title px-4 py-1";

  let coverStyle = {backgroundImage: 'url(' + coverImgUrl + ')'};

  switch (articleType) {
    case "build":
      displayArticleType = "Build Credit";
      articleTypeClasses += " featurecover-build-type";
      articleTitleClasses += " featurecover-build-type";
      break;
    case "cards":
      displayArticleType = "Credit Cards";
      articleTypeClasses += " featurecover-cards-type";
      articleTitleClasses += " featurecover-cards-type";
      break;
    case "score":
      displayArticleType = "Credit Score";
      articleTypeClasses += " featurecover-score-type";
      articleTitleClasses += " featurecover-score-type";
      break;
    case "rebuild":
      displayArticleType = "Credit Rebuild";
      articleTypeClasses += " featurecover-rebuild-type";
      articleTitleClasses += " featurecover-rebuild-type";
      break;
    default:
      displayArticleType = "Feature";
      break;
  }
  return (
    <a href={articlePath}>
      <div className={featureCoverClasses} style={coverStyle}>
        <div className="absolute featurecover-text px-4 pb-4 bottom-0 md:px-8 md:pb-8 lg:pb-12 lg:px-12">
          <div className={articleTypeClasses}><span className="text-sm font-bold md:text-lg lg:text-xl">{displayArticleType}</span></div><br/>
      	  <div className={articleTitleClasses}><span className="text-xl md:text-2xl lg:text-3xl">{articleTitle}</span></div>
        </div>
      </div>
    </a>
  )
}
FeatureCover.propTypes = {
  articleType: PropTypes.string,
  articleTitle: PropTypes.string
}

FeatureCover.defaultProps = {
  articleType: ``,
  articleTitle:``
}


export default FeatureCover
