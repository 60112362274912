import React from "react"
import './about-credit-101.css'
import AboutBuild from '../../images/about-build.svg'
import AboutCards from '../../images/about-cards.svg'
import AboutScore from '../../images/about-score.svg'

const AboutCredit101 = () => {
  return (
    <div className="about-credit-101 py-8 px-4 md:px-8 lg:px-12 bg-green-600">
      <div className="about-credit-101-wrapper mx-4">
        <h2 className="font-extrabold text-4xl pb-1">Credit 101</h2>
        <h3 className="font-bold text-lg md:text-xl lg:text-2xl pb-1">Credit 101 is the ultimate place to build credit, learn about credit score, and find your perfect credit cards.</h3>
        <p className="font-medium text-sm md:text-md lg:text-lg"> What is revolving credit? Do student loans affect credit score? How to get a perfect credit score? What is FCRA how does it help you? At Credit 101, our credit experts are here to answer all of your credit-related questions, help you make more informed decisions, and save money with your good credit. </p>
      </div>
      <div className="flex flex-wrap overflow-hidden">
        <a className="w-full overflow-hidden text-center px-4 md:w-1/3 md:p-10" href="/build">
          <div className="about-101-image-wrapper p-8 md:p-4 lg:p-8 xl:p-12"><img className="about-101-image mx-auto" src={AboutBuild} alt="Build Credit - Start your credit journey here."/></div>
          <label className="font-bold text-lg md:text-xl lg:text-2xl block">Build Credit</label>
          <p className="font-medium text-sm md:text-md lg:text-lg">Start your credit journey here.</p>
        </a>
        <a className="w-full overflow-hidden text-center px-4 md:w-1/3 md:p-10" href="/cards">
          <div className="about-101-image-wrapper p-8 md:p-4 lg:p-8 xl:p-12"><img className="about-101-image mx-auto" src={AboutCards} alt="Credit Cards - Maximize your purchases with credit cards."/></div>
          <label className="font-bold text-lg md:text-xl lg:text-2xl block">Credit Cards</label>
          <p className="font-medium text-sm md:text-md lg:text-lg">Maximize your purchases with credit cards.</p>
        </a>
        <a className="w-full overflow-hidden text-center px-4 md:w-1/3 md:p-10" href="/score">
          <div className="about-101-image-wrapper p-8 md:p-4 lg:p-8 xl:p-12"><img className="about-101-image mx-auto" src={AboutScore} alt="Credit Score - Understand what's behind the numbers."/></div>
          <label className="font-bold text-lg md:text-xl lg:text-2xl block">Credit Score</label>
          <p className="font-medium text-sm md:text-md lg:text-lg">Understand what's behind the numbers.</p>
        </a>
      </div>
    </div>
  )
}

export default AboutCredit101
